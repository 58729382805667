<template>
  <ItemWrapper>
    <v-row>
      <v-col cols="12">
        <AppCard :title="$t('Edit Quote')" :prev-route="prevRoute" :loading="pending.getEditQuote">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <v-form autocomplete="off" @submit.prevent="handleSubmit(submitForm)">
              <v-container py-0>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4">
                    <VTextFieldWithValidation label="Client" disabled :value="client" />
                  </v-col>

                  <v-col cols="12" sm="3" lg="4">
                    <DatePickerWithValidation
                      v-model="quotedate"
                      label="Quotedate"
                      name="quotedate"
                      rules="required"
                      placeholder="Enter Quotedate"
                    />
                  </v-col>

                  <v-col cols="12" sm="3" lg="2">
                    <VTextFieldWithValidation
                      v-model="quotetime"
                      label="Quotetime"
                      name="quotetime"
                      rules="required|integer|min_value:0"
                      placeholder="Enter Quotetime"
                    />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VTextFieldWithValidation label="Tenant" :value="quotePresets.tenants[tenant_id]" disabled />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VSelectWithValidation
                      v-model="quoterange_id"
                      rules="required"
                      label="Quoterange"
                      :items="quoteRangesArray"
                      menu-props="offset-y"
                      placeholder="Select Quoterange"
                    />
                  </v-col>

                  <v-col cols="12" sm="4" lg="2">
                    <VTextFieldWithValidation
                      v-model="discount"
                      label="Discount"
                      name="discount"
                      rules="integer|min_value:0"
                      placeholder="Enter Discount"
                    />
                  </v-col>

                  <v-col cols="12" sm="4" lg="2">
                    <VSelectWithValidation
                      v-model="discount_type"
                      label="Discount Type"
                      :items="discountTypes"
                      menu-props="offset-y"
                      placeholder="Select Discount Type"
                    />
                  </v-col>

                  <v-col cols="12" px-0>
                    <v-divider />
                  </v-col>

                  <v-col cols="12">
                    <h3 class="text-h5 mt-0">Positions</h3>

                    <draggable v-model="allProducts" handle=".handle">
                      <template v-for="(quote_product, key) in quote_products">
                        <QuoteProductRow
                          :key="key"
                          :index="key"
                          :length="quote_products.length"
                          :quote-product="quote_product"
                          @removeQuoteProduct="removeQuoteProduct"
                        />
                      </template>
                    </draggable>

                    <v-btn color="success" class="mt-4" @click="addQuoteProduct">Add position</v-btn>
                  </v-col>

                  <v-col cols="12" px-0>
                    <v-divider />
                  </v-col>

                  <v-col cols="12">
                    <VTextareaWithValidation
                      v-model="extratext"
                      label="Quote Text"
                      name="quotetext"
                      placeholder="Enter Quote Text"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-row justify="space-between">
                      <v-col order="2" order-sm="0" cols="12" sm="auto">
                        <v-btn
                          :loading="pending.getQuotePdfPreview"
                          class="ml-0"
                          color="success"
                          outlined
                          :disabled="pending.editQuote"
                          @click="previewQuote"
                        >
                          Preview
                        </v-btn>
                      </v-col>
                      <v-col cols="12" sm="auto" class="text-sm-right">
                        <v-btn class="mr-2" color="success" :disabled="pending.editQuote" @click="saveQuote">
                          <v-icon class="d-sm-none">mdi-content-save</v-icon>
                          <span class="d-none d-sm-inline">Speichern</span>
                        </v-btn>

                        <v-btn type="submit" class="mr-0" color="success" :disabled="pending.editQuote">
                          Bestätigen
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </AppCard>
      </v-col>
    </v-row>

    <AppDialog :is-open="visiblePdf" max-width="800px" @onCloseDialog="visiblePdf = false">
      <template slot="title">View PDF</template>

      <v-responsive :aspect-ratio="210 / 297">
        <object :data="`data:application/pdf;base64,${quotePdf}`" type="application/pdf" width="100%" height="100%" />
      </v-responsive>
    </AppDialog>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref } from '@vue/composition-api'
import { mapMultiRowFields } from 'vuex-map-fields'
import { mapFields } from 'vuex-composition-map-fields'
import { parseObjectToArray } from '@/helpers'
import { discountTypes } from '@/config'

import useDirectRedirectToItem from '@/composables/useDirectRedirectToItem'

import draggable from 'vuedraggable'

import AppCard from '@/components/UI/AppCard.vue'
import DatePickerWithValidation from '@/components/inputs/DatePickerWithValidation.vue'
import QuoteProductRow from '@/components/Quotes/QuoteProductRow.vue'
import ItemWrapper from '@/layouts/ItemWrapper.vue'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation.vue'
import VTextareaWithValidation from '@/components/inputs/VTextareaWithValidation.vue'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation.vue'
import AppDialog from '@/components/UI/AppDialog.vue'

export default defineComponent({
  name: 'EditQuote',
  components: {
    draggable,
    AppCard,
    DatePickerWithValidation,
    QuoteProductRow,
    ItemWrapper,
    VSelectWithValidation,
    VTextareaWithValidation,
    VTextFieldWithValidation,
    AppDialog,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router, $moment } }) {
    const { redirectDirect, setForceRedirect } = useDirectRedirectToItem()
    const prevRoute = ref('/Quotes')
    const visiblePdf = ref(false)
    const observer = ref(null)

    // store
    const pending = computed(_ => $store.state.quote.pending)
    const quote = computed(_ => $store.state.quote.quote)
    const quotePresets = computed(_ => $store.state.quote.quotePresets)
    const quotePdf = computed(_ => $store.state.invoice.quotePdf)
    const errors = computed(_ => $store.state.quote.errors)

    const getEditQuote = _ => $store.dispatch('quote/getEditQuote')
    const editQuote = onlyEdit => $store.dispatch('quote/editQuote', onlyEdit)
    const clearQuote = _ => $store.dispatch('quote/clearQuote')
    const addQuoteProduct = _ => $store.dispatch('quote/addQuoteProduct')
    const getQuotePdfPreview = _ => $store.dispatch('quote/getQuotePdfPreview')
    const removeQuoteProduct = productIndex => $store.dispatch('quote/removeQuoteProduct', productIndex)

    const updateAllQuoteProducts = products => $store.commit('quote/UPDATE_ALL_QUOTE_PRODUCTS', products)
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    getEditQuote()

    const quoteRangesArray = computed(_ => {
      return parseObjectToArray(quotePresets.value.quoteranges)
    })

    const client = computed(_ => {
      let client = quote.value.client.billing_handle.organisation
        ? `(${quote.value.client.billing_handle.organisation}) `
        : ''
      return (client += `${quote.value.client.billing_handle.firstname} ${quote.value.client.billing_handle.lastname}`)
    })

    const allProducts = computed({
      set: val => updateAllQuoteProducts(val),
      get: _ => quote.value.quote_products,
    })

    const saveQuote = async _ => {
      if (pending.value.editQuote) {
        return
      }

      const isValid = await observer.value.validate()
      if (isValid) {
        editQuote(false).then(({ errors }) => {
          if (errors) {
            addToast({
              msg: 'Bearbeitung fehlgeschlagen',
              type: 'error',
            })
            return
          }

          addToast({
            msg: 'Angebot erfolgreich bearbeitet',
            type: 'success',
          })
        })
      }
    }

    const previewQuote = async _ => {
      if (pending.value.getQuotePdfPreview) {
        return
      }

      const isValid = await observer.value.validate()
      if (isValid) {
        getQuotePdfPreview().then(_ => (visiblePdf.value = true))
      }
    }

    const submitForm = _ => {
      if (pending.value.editQuote) {
        return
      }
      if (redirectDirect.value) {
        setForceRedirect(true)
      }

      editQuote().then(({ quote, errors }) => {
        if (errors) {
          setForceRedirect(false)
          addToast({
            msg: 'Bearbeitung fehlgeschlagen',
            type: 'error',
          })
          return
        }

        addToast({
          msg: 'Angebot erfolgreich bearbeitet',
          type: 'success',
        })

        if (redirectDirect.value) {
          $router.push({
            name: 'quotes-item',
            params: { id: quote.id },
          })
        } else {
          $router.push({ path: prevRoute.value })
        }
      })
    }

    onUnmounted(_ => {
      clearQuote()
    })

    return {
      prevRoute,
      client,
      observer,
      visiblePdf,
      quoteRangesArray,
      quotePresets,
      quotePdf,
      pending,
      errors,
      discountTypes,
      allProducts,
      submitForm,
      saveQuote,
      previewQuote,
      addQuoteProduct,
      removeQuoteProduct,
      ...mapFields('quote', [
        'quote.client_id',
        'quote.discount_type',
        'quote.discount',
        'quote.quotedate',
        'quote.quotetime',
        'quote.tenant_id',
        'quote.quoterange_id',
        'quote.extratext',
      ]),
    }
  },
  computed: {
    ...mapMultiRowFields('quote', ['quote.quote_products']),
  },
}) //
</script>
