<template>
  <ValidationProvider :name="$attrs.label" :vid="$attrs.vid" :rules="rules">
    <v-checkbox
      v-model="computedValue"
      slot-scope="{ errors }"
      hide-details="auto"
      :error-messages="errors"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </ValidationProvider>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'VCheckboxWithValidation',
  props: {
    rules: {
      type: [
        Object,
        String,
      ],
      default: '',
    },
    value: {
      type: null,
      default: null,
    },
  },
  setup(props, { emit }) {
    const computedValue = computed({
      set: val => {
        emit('input', val)
      },
      get: _ => props.value,
    })

    if (props.value) {
      computedValue.value = props.value
    }

    return {
      computedValue,
    }
  },
})
</script>
