<template>
  <v-row class="quote_product-row" :class="{ 'pb-4': length > 1 }" wrap>
    <v-col cols="2" sm="2" md="auto" align-self="center">
      <v-icon class="handle cursor-grab">mdi-drag-variant</v-icon>
    </v-col>

    <v-col cols="5" sm="2" md="1">
      <SearchSelectByArtNr
        v-if="!quoteProduct.isDirty"
        :key="`nr-${index}`"
        v-model="quoteProduct.artNr"
        :vid="`nr-${index}`"
        @input="setArtNr"
      />
      <VTextFieldWithValidation
        v-else
        v-model="quoteProduct.artNr"
        label="Nr"
        name="nr"
        :vid="`nr-${index}`"
        rules="required|integer|min_value:0"
        placeholder="Nr"
      />
    </v-col>

    <v-col cols="5" sm="2" md="2">
      <SearchSelectByTitle
        v-if="!quoteProduct.isDirty"
        :key="`title-${index}`"
        v-model="quoteProduct.title"
        :vid="`title-${index}`"
        @input="setTitle"
      />

      <VTextFieldWithValidation
        v-else
        v-model="quoteProduct.title"
        label="Title"
        name="title"
        :vid="`title-${index}`"
        rules="required"
        placeholder="Title"
      />
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <!-- price -->
      <VTextFieldWithValidation
        v-model="quoteProduct.description"
        label="Description"
        name="description"
        :vid="`description-${index}`"
        placeholder="Enter Description"
      />
    </v-col>

    <v-col cols="6" sm="2" md="1">
      <!-- price -->
      <VTextFieldWithValidation
        v-model="quoteProduct.quantity"
        label="Quantity"
        name="quantity"
        :vid="`quantity-${index}`"
        rules="required|decimal"
        placeholder="Quantity"
      />
    </v-col>

    <v-col cols="6" sm="3" md="1">
      <!-- price -->
      <VTextFieldWithValidation
        v-model="quoteProduct.amount"
        label="Amount"
        name="amount"
        :vid="`amount-${index}`"
        rules="required|decimal|min_value:0"
        placeholder="Amount"
      />
    </v-col>

    <v-col cols="6" sm="3" md="1">
      <VTextFieldWithValidation
        v-model="quoteProduct.tax"
        label="Tax"
        name="tax"
        :vid="`tax-${index}`"
        rules="required|integer|min_value:0"
        placeholder="Tax"
      />
    </v-col>

    <v-col cols="6" sm="3" md="1">
      <VTextFieldWithValidation
        v-model="quoteProduct.discount"
        label="Discount"
        name="discount"
        :vid="`discount-${index}`"
        rules="integer|min_value:0"
        placeholder="Discount"
      />
    </v-col>

    <v-col cols="12" sm="3" lg="2">
      <VSelectWithValidation
        v-model="quoteProduct.discount_type"
        label="Discount Type"
        :items="discountTypes"
        menu-props="offset-y"
        placeholder="Select Discount Type"
      />
    </v-col>

    <v-col cols="10" sm="3" lg="2">
      <VCheckboxWithValidation v-model="quoteProduct.optional" label="Optional" name="optional" />
    </v-col>

    <v-col cols="2" sm="2" md="1" class="text-right">
      <div class="actions">
        <!-- // TODO: [Future Feature] Add Save Product to Productlist Button -->
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn class="pa-1 ml-1 mt-4 remove-button" color="error" icon v-on="on" @click="removeQuoteProduct">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </template>
          <span>Remove Product</span>
        </v-tooltip>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import SearchSelectByArtNr from '@/components/Products/SearchSelectByArtNr'
import SearchSelectByTitle from '@/components/Products/SearchSelectByTitle'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation.vue'
import { discountTypes } from '@/config'
import VCheckboxWithValidation from '@/components/inputs/VCheckboxWithValidation.vue'

export default defineComponent({
  name: 'QuoteProductRow',
  components: {
    SearchSelectByArtNr,
    SearchSelectByTitle,
    VTextFieldWithValidation,
    VSelectWithValidation,
    VCheckboxWithValidation,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    quoteProduct: {
      type: Object,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    if (props.quoteProduct.artNr) {
      props.quoteProduct.isDirty = true
    }

    const setArtNr = product => {
      Object.assign(props.quoteProduct, product)
      props.quoteProduct.amount = product.price
      props.quoteProduct.isDirty = true
    }

    const setTitle = product => {
      Object.assign(props.quoteProduct, product)
      props.quoteProduct.isDirty = true
    }

    const removeQuoteProduct = _ => {
      emit('removeQuoteProduct', props.index)
    }

    return {
      setArtNr,
      setTitle,
      removeQuoteProduct,
      discountTypes,
    }
  },
}) //
</script>

<style lang="scss">
.theme--dark {
  .quote_product-row {
    & + .quote_product-row {
      border-top: 1px solid rgba(map-get($shades, 'white'), 0.12);
    }
  }
}

.theme--light {
  .quote_product-row {
    & + .quote_product-row {
      border-top: 1px solid rgba(map-get($shades, 'black'), 0.12);
    }
  }
}
</style>
